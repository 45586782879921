import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { TermsAndConditions } from "./payment-steps";
import { IAppUser, IHoliday, IPaymentResults, ITermsAndCondition } from "../../models";
import routes from "../../routing/routes";
import { IAppState } from "../../store/app-state";
import { fetchHoliday, fetchHolidays } from "../../store/holiday";
import { paymentResultsClear } from "../../store/payment";
import { routeChange } from "../../store/session";
import Button, { ButtonHeight, ButtonType } from "../button";
import { BookingOverview, Payment } from "./payment-steps";
import { BookedBookingSubStatus } from "../../helpers/datasource-helper";

export interface IApexxPaymentView {
    holiday : IHoliday;
    hide: () => void;
}

const getSteps = () =>{
    return ["Overview","T&Cs","Payments"];
}

export const ApexxPaymentView = (props:IApexxPaymentView) => {   
    const dispatch = useDispatch();
    const appUser: IAppUser | null = useSelector((state: IAppState) => state.session.appUser);
    const {holiday, hide} = props;
    const [activeStep, setActiveStep] = useState(0);   
    const paymentResults: IPaymentResults | null = useSelector((state: IAppState) => state.payment.paymentResults); 
    const error = paymentResults ? paymentResults.error : '';
    const isProcessing: boolean = useSelector((state: IAppState) => state.payment.isProcessing); 
    const holidayBooked = holiday && BookedBookingSubStatus().includes(holiday.bookSubStatusId);
    const steps = getSteps();
    const [termsAgreed, setTermsAgreed] = useState(false);
    const [buttonEnabled,] = useState(true);
    const remainingBalance = holiday && holiday.invoice ? holiday.invoice.balance : 0;
    const deposit = holiday && holiday.invoice && holiday.invoice.deposit;
    const [paymentAmount, setPaymentAmount] = useState<number>(holidayBooked ? remainingBalance: deposit);


    const defaultTermsAndConditions: ITermsAndCondition[] = [
        ...holiday.termsAndConditions.map((tc:any) => ({
            ...tc,
            accepted: false
        }))
    ];

    const onClose = () => {
        hide();
        if (!holidayBooked) {
            dispatch(routeChange(routes.booking(holiday.bookId.toString())));
        } else {
            dispatch(fetchHoliday(holiday.bookId));
            if (appUser) {
                dispatch(fetchHolidays(appUser.id));
            }
        }
        dispatch(paymentResultsClear());
    }

    React.useEffect(() => {
        setTermsAgreed(acceptedTerm.filter(t=>t.isMandatory && !t.accepted && !t.dateOfConfirmation).length === 0);
    }, [holiday]);

    React.useEffect(() => {
        dispatch(paymentResultsClear());
    }, [dispatch]);
    
   const [acceptedTerm, setacceptedTerm] = useState(defaultTermsAndConditions);

   const handleTermsConditions = (updatedTc:ITermsAndCondition) =>{
       const tcs: ITermsAndCondition[] = [
        ...acceptedTerm.filter((tc) => tc.termId !== updatedTc.termId),{...updatedTc}
    ]; 
    setacceptedTerm(tcs);
    const requiredTerms = tcs.filter(t=>t.isMandatory && !t.accepted && !t.dateOfConfirmation).length === 0;  
    setTermsAgreed(requiredTerms);
   }

   React.useEffect(() => {
       if(paymentResults) {
           if(paymentResults.success && !paymentResults.requiresAction){ 
                setActiveStep(3);
            }
       }
   },[paymentResults, holidayBooked]);

    const handleNext = () => {     
        setActiveStep((preActiveStep) => preActiveStep + 1);        
    }
    const handlePrevious = () => {
        setActiveStep((preActiveStep) => preActiveStep - 1);     
    }

    const getButtonText = () =>{
        switch(activeStep)
        {
            case 0:
                return "Continue";
            case 1:
                return "Next";
            case 2:
                return "Close";
            default:
                return "";
        }      
    }

    const areAnyTermsAndConditionsNotAgreed = (): boolean => {
        return holiday?.termsAndConditions?.filter(t=>t.isMandatory && !t.accepted && !t.dateOfConfirmation).length !== 0;
    };

    return (
        <StepperContainer>
            <Step>
                {steps.map((stepLabel,index) => {                     
                    return (                           
                            <StepCounter key={index} className={activeStep === index ? "active":""} isActive={activeStep === index}>
                                <StepLabel step={index}>{stepLabel}</StepLabel>       
                            </StepCounter>                 
                    )
                })}
            </Step> 
            <ContentWrapper>
                {activeStep === 0 && <SlideInTransition><BookingOverview booking={props.holiday} paymentAmount={setPaymentAmount} /></SlideInTransition>}
                {activeStep === 1 && <SlideInTransition><TermsAndConditions onClick={(r)=>handleTermsConditions(r)} termsAndConditions={acceptedTerm} 
                displayDate={holidayBooked && !areAnyTermsAndConditionsNotAgreed()} businessUnitId={holiday.businessUnit.id}/>  </SlideInTransition>}
                {activeStep === 2 && <SlideInTransition>
                    <Payment holiday={props.holiday} paymentAmount={paymentAmount} acceptedTandC={acceptedTerm} />
                    </SlideInTransition>}
            </ContentWrapper> 
            <ButtonWrapper step={activeStep}>
                {
                    activeStep !== 0 && activeStep !== 3 &&
                    <StepButton type={ButtonType.ALTERNATIVE} height={ButtonHeight.TALL}
                    text= "Previous" onClick={handlePrevious}/>
                }
                {activeStep !== 3 && activeStep !==2 &&
                <StepButton type={ButtonType.MAIN} height={ButtonHeight.TALL}
                text={getButtonText()} onClick={
                    activeStep === 1 && termsAgreed ? handleNext: 
                    activeStep === 0 && paymentAmount >0 ? handleNext: undefined} 
                    
                />
                }
                 {activeStep === 2 &&
                <StepButton type={ButtonType.MAIN} height={ButtonHeight.TALL} form="hook-form-stripe"
                text={getButtonText()} onClick={onClose} 
                processing={isProcessing}
                disabled={buttonEnabled === false}
                />
                }

            </ButtonWrapper>
            {error ? <div>{error}</div>:''}
        </StepperContainer>
    );
}

const StepperContainer = styled.div`
display:flex;
flex-direction: column;
overflow:hidden;
margin:5px;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin:10px;
    height: 100%;
}
`;
const Step = styled.ul`
    counter-reset: step;
    z-index: 1;
    padding:0;

     &::first-child > div{
        text-align: left;
    };
    &::last-child{
        text-align: right;
    }
`;
const SlideInTransition  = styled.div`
width:100%;
position: relative;
animation-timing-function: ease-in-out;
animation: inAnimation 0.5s;
@keyframes inAnimation{
    from {right: -300px; }
    to {right: 0px;}
    }
`;
const ContentWrapper = styled.div`
overflow-y: scroll;
overflow-x: hidden;
padding: 0px 3px;
display: flex;
height: 100%;
// @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
//     padding: 0px 60px;
// }
`;

interface IStep {
    isActive: boolean;
}
const StepCounter = styled.li<IStep>`

list-style-type: none;
width: 33%;
float: left;
font-size: 100%;
position: relative;
text-align: right;
text-transform: uppercase;
color:${({ theme })=> theme.colors.labelDark};

&:first-child::before{
   margin-left: 0;
}
&:last-child::before{
    margin-right: 0;
}

::before{
    width: 40px;
      height: 40px;
      content: counter(step);
      counter-increment: step;
      line-height: 40px;
      font-weight:${({ isActive })=> isActive ? 500: 0};
      display: block;
      text-align: center;
      margin: 0 auto 10px auto;
      border-radius: 50%;
      background-color: ${({ isActive,theme })=> isActive ? theme.colors.primaryDark: theme.colors.labelLight};
      color: ${({ isActive,theme })=> isActive ? theme.colors.white: theme.colors.primaryDark};
}
::after{
    width: 100%;
      height: 2px;
      content: ' ';
        text-align: center;
      margin-right: 0;
      position: absolute;
      background-color:${({ theme })=> theme.colors.labelLight};
      top: 20px;
      left: 0;
      z-index: -1;
}
:last-child::after{
    // content: none;
}
`;
const StepLabel = styled.div<any>`
margin-top:10%;
text-transform:none;
font-size: 15px;
text-align:${({step})=>step === 0 ? "left": step === 1 ? "center": "right"};
`;

interface IButtonWrapper{
    step:number
}
const ButtonWrapper = styled.div<IButtonWrapper>`
margin:25px 65px;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        display:flex;        
        flex-direction:column nowrap;
        justify-content:${({step}) => (step === 0) ? "flex-end": "space-between"};
    }
`;

const StepButton = styled(Button)`
    width: 100px;
    margin-right: 10px;
    vertical-align: middle;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        width:200px;
        margin-right: 0px;
    }
`;
