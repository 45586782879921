import React from "react"
import styled from "styled-components";
import { IContact, ICountry, IEndPointKeys, ITermsAndCondition } from "../../models";
import appConfig from '../../config/app-config';
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store/app-state";
import { GetAddressLineForApexx } from "../../helpers/datasource-helper";
import { getPaymentGatewaySession } from "../../store/payment";
import '@adyen/adyen-web/dist/adyen.css';
import { AdyenPaymentPage } from "./adyen-payment-view";
import { Loader } from "../loader";


export const endPointKeys: IEndPointKeys = appConfig.endPoints;
export interface IPaymentInfo{
    amount : number;
    bookId : number;
    currency: string;
    acceptedTandC : ITermsAndCondition[];
}
export const PayView:  React.FunctionComponent<IPaymentInfo> | any = ({ amount, bookId, currency, acceptedTandC }: any) => {
    //const email: string = useSelector((state: IAppState) => state.session.appUser && state.session.appUser.primaryEmail ? state.session.appUser.primaryEmail.title : '');
    const loggedInEmail: string = useSelector((state: IAppState) => state.session.appUser && state.session.appUser.loggedInEmail ? state.session.appUser.loggedInEmail.title : '');
    const payerName: string =  useSelector((state: IAppState) => state.session.appUser?.firstName + ' ' + state.session.appUser?.lastName);
    const buId : number =  useSelector((state: IAppState) => state.holidays.holiday && state.holidays.holiday.businessUnit ? state.holidays.holiday.businessUnit.id : 1);
    const contact: IContact =  useSelector((state: IAppState) => state.contact.contact);
    const countryList: ICountry[] =  useSelector((state: IAppState) => state.referenceData.countries);   
    let paymentSessions: any = useSelector((state:IAppState)=>state.payment.paymentSessionResults);
    const dispatch = useDispatch();

    
    const [paymentGateway,setPaymentGateway] = React.useState<string>("")
    const [url,setUrl] = React.useState<string>("")
    const [paymentSession,setPaymentSession] = React.useState<string>("")
    const [paymentSessionId,setPaymentSessionId] = React.useState<string>("")    
    const [loading,setLoading] = React.useState<boolean>(true)
    const getAcceptedTerms = (): Array<number> => {
        return acceptedTandC.filter((x: any) => x.accepted).map((m: any) => m.termId);
    }
    const getCountryCode = () =>{
        if(contact.address && contact.address.country){
            const country = countryList.find(c=>c.countryId === contact.address.country.id);
            return country? country.countryCode : '';
        }
        return '';
    }

    // const method = "post";
    const data = {
        bookId: bookId,
        amount: amount,
        currency: currency,
        payType: 'WEB',
        payerName : payerName,
        payerEmail:  loggedInEmail,
        businessUnitId: buId,
        acceptedTermIds: getAcceptedTerms().toString(),
        address : contact.address ? GetAddressLineForApexx(contact.address) : '',
        address1 : contact.address.address1 ? contact.address.address1 : '',
        address2 : contact.address.address2 ? contact.address.address2 : '',
        address3 : contact.address.address3 ? contact.address.address3 : '',
        city: contact.address ? contact.address.city: '',
        postCode: contact.address? contact.address.postCode : '',
        state: contact.address? contact.address.county : '',
        country: getCountryCode(),
        returnUrl: window.location.href
    };     

    React.useEffect(() => {
        let form = new URLSearchParams();
        for(let key in data){
            form.append(`${key}`, `${data[key]}`)
        }
            paymentSessions= null;
            dispatch(getPaymentGatewaySession({paymentSessions, form}))   
    }, []);

    React.useEffect(()=>{
        if(paymentSessions) {
            if(paymentSessions.value){
                setPaymentSession(paymentSessions.value.paymentSession);
                setPaymentSessionId(paymentSessions.value.paymentSessionId);
                setPaymentGateway(paymentSessions.value.paymentGateway);
                setUrl(paymentSessions.value.paymentPageUrl);
            }
            else{
                setPaymentSession(paymentSessions.paymentSession);
                setPaymentSessionId(paymentSessions.paymentSessionId);
                setPaymentGateway(paymentSessions.paymentGateway);
                setUrl(paymentSessions.paymentPageUrl);
            }
            setLoading(false);
        }
    },[paymentSessions]);  

    return loading? <Container><Loader/></Container>: paymentSessions && paymentGateway === "ApexxGateway" && paymentSessions?.value?.paymentResult?.success? <Container> <iframe height={"1000px"} width={"100%"} src={url}/> </Container> : paymentSessionId && paymentGateway === "AdyenGateway" && paymentSessions?.paymentResult?.success? <Container><AdyenPaymentPage buId={buId} id={paymentSessionId} sessionData={paymentSession} address={contact.address} country={getCountryCode()} /> </Container>:`${paymentSessions?.paymentResult?.message || paymentSessions?.value?.paymentResult?.message || ""}`;
};



const Container = styled.div`
    padding: 20px 20px 5px 15px;
    flex-flow: column nowrap;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    // height: 100%;
    flex-direction: column;
     @media (max-width: ${({theme})=>theme.breakpoints.tablet}px){
    height: 500px;
     padding: 20px 0;
    }
    @media (min-width:${({theme})=>theme.breakpoints.tablet}px){
        height: 700px;
    }
`;